<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <Logins />
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const Logins = defineAsyncComponent(() =>
  import('@/components/Users/Logins.vue')
)
export default {
  name: "Login",
  components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    Logins,
    
  },
};
</script>